import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import './App.css';

import { FluentProvider, webLightTheme, webDarkTheme } from '@fluentui/react-components';
import { AppBar } from "./components/AppBar";
import { HeaderBar } from "./components/HeaderBar";
import { AppIcon } from "./components/AppIcon";
import { UserProfile } from "./components/UserProfile";
import { Settings } from 'components/Settings';

import HomePage from './pages/Home';
import FinicityMainPage from 'pages/Finicity/Main';
import PlaidMainPage from 'pages/Plaid/Main';
import RatePlansHomePage from 'pages/RatePlans/Home';
import ViewRatePlanPage from 'pages/RatePlans/View';
import BillingHomePage from 'pages/Billing/Home';
import ViewBillingPage from 'pages/Billing/View';
import TenantsHomePage from 'pages/Tenants/Home';

function App() {
    const [useDarkMode, setUseDarkMode] = useState(true);
    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState(null);

    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated) {
            // Set token in localStorage after authentication is complete
            localStorage.setItem('token', auth?.user?.access_token ?? '');
        }
    }, [auth.isAuthenticated, auth?.user?.access_token]);

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth.isAuthenticated, auth.isLoading]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return (
            <FluentProvider theme={webLightTheme}>
                <div>Loading...</div>
            </FluentProvider>
        );
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <FluentProvider theme={useDarkMode ? webDarkTheme : webLightTheme}>
                {isLoaded && (
                    <div className="app-container">
                        <BrowserRouter>
                            <UserProfile
                                isOpen={isUserProfileOpen}
                                onClose={() => setIsUserProfileOpen(false)}
                            />
                            <Settings
                                isOpen={isSettingsOpen}
                                onClose={() => setIsSettingsOpen(false)}
                                useDarkMode={useDarkMode}
                                onSwitchDarkMode={() => setUseDarkMode(!useDarkMode)}
                            />
                            <div className="icon">
                                <AppIcon />
                            </div>
                            <header className="header">
                                <HeaderBar
                                    onShowUserProfile={() => setIsUserProfileOpen(true)}
                                    onShowSettings={() => setIsSettingsOpen(true)}
                                />
                            </header>
                            <nav className="sidebar">
                                <AppBar />
                            </nav>
                            <div className="main">
                                <Routes>
                                    <Route path="/" element={<HomePage darkMode={useDarkMode} />} />
                                    <Route path="/RatePlans" element={<RatePlansHomePage darkMode={useDarkMode} />} />
                                    <Route path="/RatePlans/:id" element={<ViewRatePlanPage />} />
                                    <Route path="/Billing" element={<BillingHomePage darkMode={useDarkMode} />} />
                                    <Route path="/Billing/:id" element={<ViewBillingPage />} />
                                    <Route path="/Tenants" element={<TenantsHomePage darkMode={useDarkMode} />} />
                                    <Route path="/Finicity" element={<FinicityMainPage />} />
                                    <Route path="/Plaid" element={<PlaidMainPage />} />
                                </Routes>
                            </div>
                        </BrowserRouter>
                    </div>
                )}
            </FluentProvider>
        );
    }

    return null; // Ensure there's a return statement in case auth.isAuthenticated is false and signinRedirect is ongoing
}

export default App;
